import React from 'react';
import Navbar from './global-components/navbar';
import Banner from './section-components/banner';
import Aboutv1 from './section-components/about-v1';
import Counter from './section-components/counter-v1';
import AboutV2 from './section-components/about-v2';
import Featuresv1 from './section-components/features-v1';
import Apartment from './section-components/apartment-v1';
import BlogSlider from './blog-components/blog-slider-v1';
import Footer from './global-components/footer';

const Home_V1 = () => {

    return <div>
        <Navbar />
        <Banner />
        <Aboutv1 id="section1" title="Vision" />
        <Counter />
        <AboutV2 id="section2" title="Services" />
        <Featuresv1 customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---" />
        <BlogSlider id="section3" title="Projects" customClass="section-subtitle-2" />
        <Apartment id="section4" title="Policy" />
        <Footer />
    </div>
}

export default Home_V1

