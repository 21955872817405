import React from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const FeaturesV1 = ({ customClass }) => {
	let publicUrl = process.env.PUBLIC_URL + '/'
	let lang = localStorage.getItem("lang")
	return (
		<div className={customClass ? customClass : ''} >
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="section-title-area ltn__section-title-2--- text-center">
							<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color" style={{ fontFamily: lang === "ar" && 'tajawal' }}>{lang === "ar" ? "ما تتميز به مشاريعنا" : "What distinguishes our projects"}</h6>
						</div>
					</div>
				</div>
				<div className="row ltn__custom-gutter--- justify-content-center go-top">
					<div className="col-lg-3 col-sm-6 col-12">
						<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
							<div className="ltn__feature-icon">
								<img src={publicUrl + "assets/img/icons/icon-img/21.png"} alt="#" />
							</div>
							<div className="ltn__feature-info">
								<h4 style={{ fontFamily: lang === "ar" && 'tajawal' }}><Link>{lang === "ar" ? "جودة عالية" : "High quanlity"}</Link></h4>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6 col-12">
						<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
							<div className="ltn__feature-icon">
								<img src={publicUrl + "assets/img/icons/icon-img/22.png"} alt="#" />
							</div>
							<div className="ltn__feature-info">
								<h4 style={{ fontFamily: lang === "ar" && 'tajawal' }}><Link>{lang === "ar" ? "المواقع الاستراتيجية" : "Strategic locations"}</Link></h4>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6 col-12">
						<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
							<div className="ltn__feature-icon">
								<img src={publicUrl + "assets/img/icons/icon-img/23.png"} alt="#" />
							</div>
							<div className="ltn__feature-info">
								<h4 style={{ fontFamily: lang === "ar" && 'tajawal' }}><Link>{lang === "ar" ? "خطط متكاملة" : "Integrated plans"}</Link></h4>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-sm-6 col-12">
						<div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
							<div className="ltn__feature-icon">
								<img src={publicUrl + "assets/img/icons/icon-img/23.png"} alt="#" />
							</div>
							<div className="ltn__feature-info">
								<h4 style={{ fontFamily: lang === "ar" && 'tajawal' }}><Link>{lang === "ar" ? "تصاميم متجددة" : "Renewed designs"}</Link></h4>
								{/* <p>over 1 million+ homes for sale available on the website, we can match you with a house you will want to call home.</p>
								<Link className="ltn__service-btn" to="/service-details">Find A Home <i className="flaticon-right-arrow" /></Link> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default FeaturesV1