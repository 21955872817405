import React from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const AboutV2 = ({ id }) => {
	let publicUrl = process.env.PUBLIC_URL + '/'
	let lang = localStorage.getItem("lang")
	return (
		<div id={id} className="ltn__about-us-area pt-120 pb-90 ">
			<div className="container">
				<div className="row" style={{ direction: lang === "ar" ? "rtl" : "ltr" }}>
					<div className="col-lg-6 align-self-center">
						<div className="about-us-info-wrap">
							<div className="section-title-area ltn__section-title-2--- mb-30">
								<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color" style={{ fontFamily: lang === "ar" && 'tajawal' }}>{lang === "ar" ? "خدماتنا" : "Our Services"}</h6>
								<h1 className="section-title" style={{ fontFamily: lang === "ar" && 'tajawal' }}>{lang === "ar" ? "الإعمار للتطوير" : "Al Emmar Development"}</h1>
								{lang === "ar" ?
									<p style={{ fontFamily: lang === "ar" && 'tajawal' }}>تقدم شركة الإعمار للتطوير خدمات متنوعة تلبي كافة متطلبات السوق العقاري، منها:</p>
									:
									<p>Al Emmar Development offers a variety of services that meet all the requirements of the real estate market, including:</p>
								}
							</div>
							<ul className="ltn__list-item-1 ltn__list-item-1-before clearfix">
								<li style={{ fontFamily: lang === "ar" && 'tajawal' }}>{lang === "ar" ? "التطوير العقاري وإدارة المشاريع" : "Real estate development and project management"}</li>
								<li style={{ fontFamily: lang === "ar" && 'tajawal' }}>{lang === "ar" ? "إدارة المحافظ الاستثمارية" : "Investment portfolio management"}</li>
								<li style={{ fontFamily: lang === "ar" && 'tajawal' }}>{lang === "ar" ? "إدارة المرافق" : "Facilities management"}</li>
								<li style={{ fontFamily: lang === "ar" && 'tajawal' }}>{lang === "ar" ? "التسويق العقاري" : "Real Estate Marketing"}</li>
							</ul>
							{/* <ul className="ltn__list-item-2 ltn__list-item-2-before ltn__flat-info">
								<li><span>3 <i className="flaticon-bed" /></span>
									Bedrooms
								</li>
								<li><span>2 <i className="flaticon-clean" /></span>
									Bathrooms
								</li>
								<li><span>2 <i className="flaticon-car" /></span>
									Car parking
								</li>
								<li><span>3450 <i className="flaticon-square-shape-design-interface-tool-symbol" /></span>
									square Ft
								</li>
							</ul>
							<ul className="ltn__list-item-2 ltn__list-item-2-before--- ltn__list-item-2-img mb-50">
								<li>
									<a href={publicUrl + "assets/img/img-slide/11.jpg"} data-rel="lightcase:myCollection">
										<img src={publicUrl + "assets/img/img-slide/11.jpg"} alt="Image" />
									</a>
								</li>
								<li>
									<a href={publicUrl + "assets/img/img-slide/12.jpg"} data-rel="lightcase:myCollection">
										<img src={publicUrl + "assets/img/img-slide/12.jpg"} alt="Image" />
									</a>
								</li>
								<li>
									<a href={publicUrl + "assets/img/img-slide/13.jpg"} data-rel="lightcase:myCollection">
										<img src={publicUrl + "assets/img/img-slide/13.jpg"} alt="Image" />
									</a>
								</li>
							</ul> */}
						</div>
					</div>
					<div className="col-lg-6 align-self-center">
						<div className="about-us-img-wrap about-img-right">
							{/* <img src={publicUrl+"assets/img/others/9.png"} alt="About Us Image" /> */}
							<img src={publicUrl + "assets/img/services.jpeg"} alt="Goal" />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AboutV2