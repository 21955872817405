import React from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const AboutV1 = ({ id }) => {

	let publicUrl = process.env.PUBLIC_URL + '/'
	let lang = localStorage.getItem("lang")
	return (
		<div id={id} className="ltn__about-us-area pt-120 pb-90 ">
			<div className="container">
				<div className="row" style={{ direction: lang === "ar" ? "rtl" : "ltr" }}>
					<div className="col-lg-6 align-self-center">
						<div className="about-us-img-wrap about-img-left">
							<img src={publicUrl + "assets/img/vision.jpeg"} alt="Vision" />
						</div>
					</div>
					<div className="col-lg-6 align-self-center">
						<div className="about-us-info-wrap">
							<div className="section-title-area ltn__section-title-2--- mb-20">
								<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color" style={{ fontFamily: lang === "ar" && 'tajawal' }}>{lang === "ar" ? "رؤيتنا" : "Our Vision"}</h6>
								<h1 className="section-title" style={{ fontFamily: lang === "ar" && 'tajawal' }}>{lang === "ar" ? "العقارية الرائدة" : "The Leading Real Estate"}
								</h1>
								{lang === "ar" ?
									<p style={{ fontFamily: 'tajawal' }}>رؤيتنا هي أن نكون واحدة من الشركات الرائدة وأفضل المطورين العقاريين</p>
									:
									<p>Our Vision is to be one of the leading companies and the best real estate developer</p>
								}
							</div>
							<ul className="ltn__list-item-half clearfix">
								<li style={{ fontFamily: lang === "ar" && 'tajawal' }}>
									<i className="flaticon-home-2" />
									{lang === "ar" ? "مشاريع راقية" : "Luxury projects"}
								</li>
								<li style={{ fontFamily: lang === "ar" && 'tajawal' }}>
									<i className="flaticon-mountain" />
									{lang === "ar" ? "انشاءات ذات جودة عالية" : "High end construction"}
								</li>
								<li style={{ fontFamily: lang === "ar" && 'tajawal' }}>
									<i className="flaticon-heart" />
									{lang === "ar" ? "مخططات هندسية متكاملة" : "Complete Engineering Plans"}
								</li>
								<li style={{ fontFamily: lang === "ar" && 'tajawal' }}>
									<i className="flaticon-secure" />
									{lang === "ar" ? "تصاميم متجددة" : "Innovative designs"}
								</li>
								<li style={{ fontFamily: lang === "ar" && 'tajawal' }}>
									<i className="flaticon-mountain" />
									{lang === "ar" ?  "اسلوب حياة استثنائي" : "Extraordinary lifestyle"}
								</li>
								<li style={{ fontFamily: lang === "ar" && 'tajawal' }}>
									<i className="flaticon-heart" />
									{lang === "ar" ? "سرعة التنفيذ" : "Execution speed"}
								</li>
								<li style={{ fontFamily: lang === "ar" && 'tajawal' }}>
									<i className="flaticon-home-1" />
									{lang === "ar" ? "دقة التنفيذ": "Implementation Accuracy"}
								</li>
								<li style={{ fontFamily: lang === "ar" && 'tajawal' }}>
									<i className="flaticon-location" />
									{lang === "ar" ? "مواقع مميزة واستراتيجية" : "Distinctive and strategic locations"}
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AboutV1