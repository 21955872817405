import React from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const ApartmentV1 = ({ id, CustomClass }) => {


	let publicUrl = process.env.PUBLIC_URL + '/'
	let CustomClasss = CustomClass ? CustomClass : ''
	let lang = localStorage.getItem("lang")

	return (
		<div id={id} className={"ltn__apartments-plan-area pt-115--- pb-70 " + CustomClasss}>
			<div className="container">
				<div className="row" style={{ direction: lang === "ar" ? "rtl" : "ltr" }}>
					<div className="col-lg-12">
						<div className="section-title-area ltn__section-title-2--- text-center">
							<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color" style={{ fontFamily: lang === "ar" && 'tajawal' }}>{lang === "ar" ? "سياستنا وهدفنا" : "Our Policy & Goal"}</h6>
						</div>
						<div className="tab-content">
							<div className="tab-pane fade active show" id="liton_tab_3_2">
								<div className="ltn__product-tab-content-inner">
									{/* <div className="row"> */}
									{/* <div className="col-lg-12"> */}
									<div className="d-flex justify-content-center">
										<div className="apartments-plan-info ltn__secondary-bg text-color-white">
											<h2 style={{ textDecorationLine: "underline", fontFamily: lang === "ar" && 'tajawal' }} >{lang === "ar" ? "سياستنا وهدفنا" : "Our Policy & Goal"}</h2>
											<ul className='mt-4'>
												<li style={{ marginTop: "0", fontFamily: lang === "ar" && 'tajawal' }}>{lang === "ar" ? "مراقبة السوق" : "Market monitoring"}</li>
												<li style={{ marginTop: "0", fontFamily: lang === "ar" && 'tajawal' }}>{lang === "ar" ? "دراسة الفرص": "Study opportunities"}</li>
												<li style={{ marginTop: "0", fontFamily: lang === "ar" && 'tajawal' }}>{lang === "ar" ? "تطوير العلاقات وإقامة التحالفات" : "Developing relationships and establishing alliances"}</li>
												<li style={{ marginTop: "0", fontFamily: lang === "ar" && 'tajawal' }}>{lang === "ar" ? "إعمار الأرض بإحسان" : "Building the land with kindness"}</li>
											</ul>
										</div>
									</div>
									{/* <div className="col-lg-6">
											<div className="apartments-plan-img">
												<img src={publicUrl + "assets/img/others/10.png"} alt="#" />
											</div>
										</div> */}
									{/* </div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ApartmentV1