import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class CounterV1 extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'
		let lang = localStorage.getItem("lang")
		return <div className="ltn__counterup-area section-bg-1 pt-120 pb-70">
			<div className="container">
				<div className="row">
					<div className="col-md-6 col-sm-6 align-self-center">
						<div className="ltn__counterup-item text-color-white---">
							<div className="counter-icon">
								<i className="flaticon-select" />
							</div>
							<h1><span className="counter">92</span><span className="counterUp-icon">K+</span> </h1>
							<h6 style={{ fontFamily: lang === "ar" && 'tajawal' }}>{lang === "ar" ? "إجمالي المساحة المربعة" : "Total Area Sq"}</h6>
						</div>
					</div>
					<div className="col-md-6 col-sm-6 align-self-center">
						<div className="ltn__counterup-item text-color-white---">
							<div className="counter-icon">
								<i className="flaticon-office" />
							</div>
							<h1><span className="counter">470</span><span className="counterUp-icon">+</span> </h1>
							<h6 style={{ fontFamily: lang === "ar" && 'tajawal' }}>{lang === "ar" ? "الوحدات المباعة" : "Units Sold"}</h6>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}

export default CounterV1