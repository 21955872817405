import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import Social from '../section-components/social';
import Copyright from './copyright';
import { Link, animateScroll as scroll } from 'react-scroll';

const Footer_v1 = () => {
	let lang = localStorage.getItem("lang")
	const [width, setWidth] = useState(window.innerWidth);
	useEffect(() => {
		function handleResize() {
			setWidth(window.innerWidth);
		}
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [width]);

	useEffect(() => {

		const $ = window.$;

		let publicUrl = process.env.PUBLIC_URL + '/'
		const minscript = document.createElement("script");
		minscript.async = true;
		minscript.src = publicUrl + "assets/js/main.js";

		document.body.appendChild(minscript);

		$('.go-top').find('a').on('click', function () {

			$(".quarter-overlay").fadeIn(1);

			$(window).scrollTop(0);

			setTimeout(function () {
				$(".quarter-overlay").fadeOut(300);
			}, 800);

		});


		$(document).on('click', '.theme-btn-1 ', function () {
			$('div').removeClass('modal-backdrop');
			$('div').removeClass('show');
			$('div').removeClass('fade');
			$('body').attr("style", "");
		});
	}, [width]);


	let publicUrl = process.env.PUBLIC_URL + '/'
	let imgattr = "Footer logo"

	return (
		<footer className="ltn__footer-area  ">
			<div className="footer-top-area  section-bg-2 plr--5">
				<div className="container-fluid">
					<div style={{ display: "flex", justifyContent: "space-evenly", flexDirection: width < 767 && "column" }}>
						<div>
							<div className="footer-widget footer-about-widget">
								<div className="footer-logo">
									<div className="site-logo">
										<img src={publicUrl + "assets/img/emr-ft-logo.png"} alt="Logo" style={{ width: "100px", height: "100px" }} />
									</div>
								</div>
								<div className="footer-address">
									<ul>
										<li>
											<div className="footer-address-icon">
												<i className="icon-placeholder" />
											</div>
											<div className="footer-address-info" style={{ cursor: "pointer" }} onClick={() => window.open("https://www.google.com/maps/dir//RM3F%2BGHJ+Shorofat,+An+Nada,+Riyadh+13317/@24.8038382,46.6739055,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3e2efdc79a15ad7f:0xc7790676e43a04fe!2m2!1d46.6739055!2d24.8038382?entry=ttu")}>
												<p style={{ fontFamily: lang === "ar" && 'tajawal' }}>{lang === "ar" ? "الرياض - طريق أبو بكر - شرفات بارك" : "Riyadh - Abu Bakr Road - Shorofat Park"}</p>
											</div>
										</li>
										<li>
											<div className="footer-address-icon">
												<i className="icon-call" />
											</div>
											<div className="footer-address-info">
												<p><a href="tel:92002 46 42">92002 46 42</a></p>
											</div>
										</li>
										<li>
											<div className="footer-address-icon">
												<i className="icon-mail" />
											</div>
											<div className="footer-address-info">
												<p><a href="mailto:info@alemmar-sa.com">info@alemmar-sa.com</a></p>
											</div>
										</li>
									</ul>
								</div>
								<div className="ltn__social-media mt-20">
									<Social />
								</div>
							</div>
						</div>
						<div>
							<div className="footer-widget footer-menu-widget clearfix">
								<h4 className="footer-title" style={{ fontFamily: lang === "ar" && 'tajawal' }}>{lang === "ar" ? "شركة" : "Company"}</h4>
								<div className="footer-menu go-top">
									<ul>
										<li style={{ fontFamily: lang === "ar" && 'tajawal', cursor: "pointer" }}>
											<Link
												to="section1"
												spy={true}
												smooth={true}
												offset={-70}
												duration={500}
											>
												{lang === "ar" ? "رؤية" : "Vision"}
											</Link></li>
										<li style={{ fontFamily: lang === "ar" && 'tajawal', cursor: "pointer" }}>
											<Link
												to="section2"
												spy={true}
												smooth={true}
												offset={-70}
												duration={500}
											>
												{lang === "ar" ? "خدمات" : "Services"}
											</Link></li>
										<li style={{ fontFamily: lang === "ar" && 'tajawal', cursor: "pointer" }}>
											<Link to="section3"
												spy={true}
												smooth={true}
												offset={-70}
												duration={500}>{lang === "ar" ? "مشروع" : "Project"}</Link>
										</li>
										<li style={{ fontFamily: lang === "ar" && 'tajawal', cursor: "pointer" }}>
											<Link to="section4"
												spy={true}
												smooth={true}
												offset={-70}
												duration={500}>
												{lang === "ar" ? "سياسة" : "Policy"}
											</Link></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					{/* <div className="row">
							<div className="col-xl-6 col-md-6 col-sm-6 col-12">
								<div className="footer-widget footer-about-widget">
									<div className="footer-logo">
										<div className="site-logo">
											<img src={publicUrl + "assets/img/emr-ft-logo.png"} alt="Logo" style={{ width: "100px", height: "100px" }} />
										</div>
									</div>
									<div className="footer-address">
										<ul>
											<li>
												<div className="footer-address-icon">
													<i className="icon-placeholder" />
												</div>
												<div className="footer-address-info">
													<p>Riyadh - Abu Bakr Road - Shorofat Park</p>
												</div>
											</li>
											<li>
												<div className="footer-address-icon">
													<i className="icon-call" />
												</div>
												<div className="footer-address-info">
													<p><a href="tel:92002 46 42">92002 46 42</a></p>
												</div>
											</li>
											<li>
												<div className="footer-address-icon">
													<i className="icon-mail" />
												</div>
												<div className="footer-address-info">
													<p><a href="mailto:example@example.com">example@example.com</a></p>
												</div>
											</li>
										</ul>
									</div>
									<div className="ltn__social-media mt-20">
										<Social />
									</div>
								</div>
							</div>
							<div className="col-xl-6 col-md-6 col-sm-6 col-12">
								<div className="footer-widget footer-menu-widget clearfix">
									<h4 className="footer-title">Company</h4>
									<div className="footer-menu go-top">
										<ul>
											<li><Link to="/about">About</Link></li>
											<li><Link to="/blog-grid">Blog</Link></li>
											<li><Link to="/shop">All Products</Link></li>
											<li><Link to="/contact">Contact</Link></li>
											<li><Link to="/faq">FAQ</Link></li>
											<li><Link to="/contact">Contact us</Link></li>
										</ul>
									</div>
								</div>
							</div>
						</div> */}
				</div>
			</div>
			<Copyright />
		</footer>
	)
}


export default Footer_v1