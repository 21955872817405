import React, { useEffect, useState } from 'react';
import Social from '../section-components/social';
import { Link, animateScroll as scroll } from 'react-scroll';

const Navbar = () => {
	let lang = localStorage.getItem("lang")
	let publicUrl = process.env.PUBLIC_URL + '/'
	const [width, setWidth] = useState(window.innerWidth);
	useEffect(() => {
		function handleResize() {
			setWidth(window.innerWidth);
		}
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [width]);
	return (
		<div>
			<header className="ltn__header-area ltn__header-5 ltn__header-transparent--- gradient-color-4---">
				<div className="ltn__header-top-area section-bg-6 top-area-color-white---">
					<div className="container">
						<div className="row">
							<div className="col-md-7">
								<div className="ltn__top-bar-menu">
									<ul>
										<li><a href="mailto:info@alemmar-sa.com?Subject=Flower%20greetings%20to%20you"><i className="icon-mail" />info@alemmar-sa.com</a></li>
										<li style={{ fontFamily: lang === "ar" && 'tajawal' }}><a href="https://www.google.com/maps/dir//RM3F%2BGHJ+Shorofat,+An+Nada,+Riyadh+13317/@24.8038382,46.6739055,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3e2efdc79a15ad7f:0xc7790676e43a04fe!2m2!1d46.6739055!2d24.8038382?entry=ttu"><i className="icon-placeholder" />{lang === "ar" ? "الرياض - طريق أبو بكر -   شرفات بارك " : "Riyadh - Abu Bakr Road - Shorofat Park"}</a></li>
									</ul>
								</div>
							</div>
							<div className="col-md-5">
								<div className="top-bar-right text-end">
									<div className="ltn__top-bar-menu">
										<ul>
											<li className="d-none">
												{/* ltn__language-menu */}
												<div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
													<ul>
														<li><a href="#" className="dropdown-toggle"><span className="active-currency">English</span></a>
															<ul>
																<li><Link to="#">Arabic</Link></li>
																<li><Link to="#">Bengali</Link></li>
																<li><Link to="#">Chinese</Link></li>
																<li><Link to="#">English</Link></li>
																<li><Link to="#">French</Link></li>
																<li><Link to="#">Hindi</Link></li>
															</ul>
														</li>
													</ul>
												</div>
											</li>
											<li>
												<Social />
											</li>
											{/* <li>
													<div className="header-top-btn">
														<Link to="/add-listing">Add Listing</Link>
													</div>
												</li> */}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white">
					<div className="container">
						<div className="row">
							<div className={width < 767 ? "col-6" : "col"}>
								<div className="site-logo-wrap" style={{ padding: width < 767 && "unset", margin: width < 767 && "unset" }}>
									<div className="site-logo go-top" style={{ minWidth: width < 767 && "unset", width: width < 767 && "100%" }}>
										<Link to="/"><img src={publicUrl + "assets/img/emr-logo.png"} style={{ width: width < 767 ? "80px" : "100px", height: width < 767 ? "80px" : "100px" }} alt="Logo" /></Link>
									</div>
									<div className="get-support clearfix d-none">
										<div className="get-support-icon">
											<i className="icon-call" />
										</div>
										<div className="get-support-info">
											<h6>Get Support</h6>
											<h4><a href="tel:+123456789">123-456-789-10</a></h4>
										</div>
									</div>
								</div>
							</div>
							{width > 1200 && (
								<div className={"col header-menu-column"}>
									<div className="header-menu d-none d-xl-block">
										<nav>
											<div className="ltn__main-menu go-top" style={{ direction: lang === "ar" ? "rtl" : "ltr" }}>
												<ul>
													<li><Link
														style={{ cursor: "pointer", fontFamily: lang === "ar" && 'tajawal' }}
														to="section1"
														spy={true}
														smooth={true}
														offset={-70}
														duration={500}
													>{lang === "ar" ? "رؤية" : "Vision"}</Link></li>
													<li><Link
														style={{ cursor: "pointer", fontFamily: lang === "ar" && 'tajawal' }}
														to="section2"
														spy={true}
														smooth={true}
														offset={-70}
														duration={500}>{lang === "ar" ? "خدمات" : "Services"}</Link></li>
													<li><Link
														style={{ cursor: "pointer", fontFamily: lang === "ar" && 'tajawal' }}
														to="section3"
														spy={true}
														smooth={true}
														offset={-70}
														duration={500}>{lang === "ar" ? "المشاريع" : "Projects"}</Link></li>
													<li><Link
														style={{ cursor: "pointer", fontFamily: lang === "ar" && 'tajawal' }}
														to="section4"
														spy={true}
														smooth={true}
														offset={-70}
														duration={500}>{lang === "ar" ? "سياسة" : "Policy"}</Link></li>
													{/* <li><Link to="/">Team</Link></li> */}
												</ul>
											</div>
										</nav>
									</div>
								</div>
							)}
							<div className={width < 767 ? "col-6 ltn__header-options ltn__header-options-2 mb-sm-20" : "col ltn__header-options ltn__header-options-2 mb-sm-20"} style={{ flex: width < 767 && "unset", justifyContent: width < 767 && "flex-end" }}>
								<div className="mobile-menu-toggle d-xl-none">
									<a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle">
										<svg viewBox="0 0 800 600">
											<path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
											<path d="M300,320 L540,320" id="middle" />
											<path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
										</svg>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
			<div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu">
				<div className="ltn__utilize-menu-inner ltn__scrollbar">
					<div className="ltn__utilize-menu-head">
						<div className="site-logo">
							<Link to="/"><img src={publicUrl + "assets/img/emr-logo.png"} alt="Logo" style={{ width: "80px", height: "80px" }} /></Link>
						</div>
						<button className="ltn__utilize-close">×</button>
					</div>
					{/* <div className="ltn__utilize-menu-search-form">
						<form action={"#"}>
							<input type="text" placeholder="Search..." />
							<button><i className="fas fa-search" /></button>
						</form>
					</div> */}
					<div className="ltn__utilize-menu">
						<ul>
							<li><Link
								style={{ cursor: "pointer", fontFamily: lang === "ar" && 'tajawal' }}
								to="section1"
								spy={true}
								smooth={true}
								offset={-70}
								duration={500}>{lang === "ar" ? "رؤية" : "Vision"}</Link></li>
							{/* <li><Link to="/shop">Goal</Link></li> */}
							{/* <li><Link to="/shop">Team</Link></li> */}
							<li><Link
								style={{ cursor: "pointer", fontFamily: lang === "ar" && 'tajawal' }}
								to="section2"
								spy={true}
								smooth={true}
								offset={-70}
								duration={500}>{lang === "ar" ? "خدمات" : "Services"}</Link></li>
							<li><Link
								style={{ cursor: "pointer", fontFamily: lang === "ar" && 'tajawal' }}
								to="section3"
								spy={true}
								smooth={true}
								offset={-70}
								duration={500}>{lang === "ar" ? "المشاريع" : "Projects"}</Link></li>
							<li><Link
								style={{ cursor: "pointer", fontFamily: lang === "ar" && 'tajawal' }}
								to="section4"
								spy={true}
								smooth={true}
								offset={-70}
								duration={500}>{lang === "ar" ? "سياسة" : "Policy"}</Link></li>
						</ul>
						<hr style={{ margin: 0, marginBottom: "10px" }} />
					</div>
					{/* <div className="ltn__utilize-buttons ltn__utilize-buttons-2">
						<ul>
							<li>
								<Link to="/my-account" title="My Account">
									<span className="utilize-btn-icon">
										<i className="far fa-user" />
									</span>
									My Account
								</Link>
							</li>
							<li>
								<Link to="/wishlist" title="Wishlist">
									<span className="utilize-btn-icon">
										<i className="far fa-heart" />
										<sup>3</sup>
									</span>
									Wishlist
								</Link>
							</li>
							<li>
								<Link to="/cart" title="Shoping Cart">
									<span className="utilize-btn-icon">
										<i className="fas fa-shopping-cart" />
										<sup>5</sup>
									</span>
									Shoping Cart
								</Link>
							</li>
						</ul>
					</div> */}
					{/* <div className="ltn__social-media-2">
						<ul>
							<li><a href="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
							<li><a href="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
							<li><a href="#" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
							<li><a href="#" title="Instagram"><i className="fab fa-instagram" /></a></li>
						</ul>
					</div> */}
				</div>
			</div>
		</div>
	)
}

export default Navbar;