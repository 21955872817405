import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const Social = () => {

	let publicUrl = process.env.PUBLIC_URL + '/'
	const handleChangeLanguage = () => {
		localStorage.getItem("lang") === "ar" ? localStorage.setItem("lang", "en") : localStorage.setItem("lang", "ar")
		window.location.reload()
	}
	return (
		<div className="ltn__social-media">
			<ul>
				<li><a href="https://twitter.com/alemmar_sa?s=11&t=nK4khaZK0hMcbK7Ms0dmOQ" title="Twitter"><i className="fab fa-twitter" /></a></li>
				<li><a href="https://www.instagram.com/alemmar_sa/?igshid=OGQ5ZDc2ODk2ZA%3D%3D" title="Instagram"><i className="fab fa-instagram" /></a></li>
				<li><a title="Language" style={{ cursor: "pointer" }} onClick={() => handleChangeLanguage()}><i className="fa fa-globe" /></a></li>
			</ul>
		</div>
	)
}

export default Social