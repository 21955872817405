import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CopyRight extends Component {

	render() {
		let lang = localStorage.getItem("lang")
		let publicUrl = process.env.PUBLIC_URL + '/'
		return (
			<div className="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
				<div className="container-fluid ltn__border-top-2">
					<div className="row">
						<div className="col-md-12 col-12">
							<div className="ltn__copyright-design clearfix">
								<p style={{ textAlign: "center", fontFamily: lang === "ar" && 'tajawal' }}>{lang === "ar" ? "جميع الحقوق محفوظة لدى شركة الإعمار للتطوير العقاري" : "All Rights Reserved @ AL-EMMAR OCEAN DEVELOPMENT"} <span className="current-year" /></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}


export default CopyRight