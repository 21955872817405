import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './sidebar';

class BlogSlider extends Component {
	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		let sectionClass = this.props.sectionClass ? this.props.sectionClass : ''
		let lang = localStorage.getItem("lang")
		return (
			<div id={this.props.id} className={"ltn__blog-area pt-115--- pb-70 go-top " + sectionClass}>
				<div className="section-title-area ltn__section-title-2--- text-center pt-3">
					<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color" style={{ fontFamily: lang === "ar" && 'tajawal' }}>{lang === "ar" ? "مشاريعنا" : "Our Projects"}</h6>
				</div>
				<div className="container">
					<div className="pt-5 row ltn__blog-slider-one-active slick-arrow-1 ltn__blog-item-3-normal">
						<div className="col-lg-12">
							<div className="ltn__blog-item ltn__blog-item-3">
								<div className="ltn__blog-img">
									<Link><img style={{ height: "350px", width: "100%", objectFit: "cover" }} src={publicUrl + "assets/img/shorofat.JPG"} alt="#" /></Link>
								</div>
								<div className="ltn__blog-brief">
									<h3 style={{ fontFamily: lang === "ar" && 'tajawal' }} className="ltn__blog-title"><Link>{lang === "ar" ? "شرفات بارك" : "Shorofat Park"}</Link></h3>
								</div>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="ltn__blog-item ltn__blog-item-3">
								<div className="ltn__blog-img">
									<Link><img style={{ height: "350px", width: "100%", objectFit: "cover" }} src={publicUrl + "assets/img/crystal.png"} alt="#" /></Link>
								</div>
								<div className="ltn__blog-brief">
									<h3 style={{ fontFamily: lang === "ar" && 'tajawal' }} className="ltn__blog-title"><Link>{lang === "ar" ? "كريستال" : "Crystal"}</Link></h3>
								</div>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="ltn__blog-item ltn__blog-item-3">
								<div className="ltn__blog-img">
									<Link><img style={{ height: "350px", width: "100%", objectFit: "cover" }} src={publicUrl + "assets/img/Lussso.JPG"} alt="#" /></Link>
								</div>
								<div className="ltn__blog-brief">
									<h3 style={{ fontFamily: lang === "ar" && 'tajawal' }} className="ltn__blog-title"><Link>{lang === "ar" ? "لوسو فيلا" : "Lussso Villas"}</Link></h3>
								</div>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="ltn__blog-item ltn__blog-item-3">
								<div className="ltn__blog-img">
									<Link><img style={{ height: "350px", width: "100%", objectFit: "cover" }} src={publicUrl + "assets/img/sky.JPG"} alt="#" /></Link>
								</div>
								<div className="ltn__blog-brief">
									<h3 style={{ fontFamily: lang === "ar" && 'tajawal' }} className="ltn__blog-title"><Link>{lang === "ar" ? "سكاي فيلا" : "Sky Villa"}</Link></h3>
								</div>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="ltn__blog-item ltn__blog-item-3">
								<div className="ltn__blog-img">
									<Link><img style={{ height: "350px", width: "100%", objectFit: "cover" }} src={publicUrl + "assets/img/ramliya.jpg"} alt="#" /></Link>
								</div>
								<div className="ltn__blog-brief">
									<h3 style={{ fontFamily: lang === "ar" && 'tajawal' }} className="ltn__blog-title"><Link>{lang === "ar" ? "رملية" : "Ramliya"}</Link></h3>
								</div>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="ltn__blog-item ltn__blog-item-3">
								<div className="ltn__blog-img">
									<Link><img style={{ height: "350px", width: "100%", objectFit: "cover" }} src={publicUrl + "assets/img/tropica.png"} alt="#" /></Link>
								</div>
								<div className="ltn__blog-brief">
									<h3 style={{ fontFamily: lang === "ar" && 'tajawal' }} className="ltn__blog-title"><Link>{lang === "ar" ? "تروبيكا" : "Tropica"}</Link></h3>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default BlogSlider;
